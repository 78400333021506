*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    font-family: BlinkMacSystemFont, -apple-system, Roboto, Ubuntu, Segoe UI,
        "Helvetica Neue", Helvetica, Arial, sans-serif;

    --f-headline: "DM Serif Display", serif;
    --f-xxxxl: 4.209rem;
    --f-xxxl: 3.157rem;
    --f-xxl: 2.369rem;
    --f-xl: 1.777rem;
    --f-l: 1.333rem;
    --f-m: 1rem;
    --f-s: 0.75rem;
    --f-xs: 0.563rem;
    --f-xxs: 0.422rem;

    --color: #b893ef;

    /* until 1441 px design, then exchange with vw */
    /* max screen values */
    --gap: 0.5vw;
}

@media (min-width: 1441px) {
    :root {
        --f-xxxxl: 4.209vw;
        --f-xxxl: 3.157vw;
        --f-xxl: 2.369vw;
        --f-xl: 1.777vw;
        --f-l: 1.333vw;
        --f-m: 1vw;
        --f-s: 0.75vw;
        --f-xs: 0.563vw;
        --f-xxs: 0.422vw;
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    color: #000;
    line-height: 1.5;
}

ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
}

li + li {
    margin-top: 8px;
}

img {
    filter: grayscale(100%);
    transition: all ease-in-out 60ms;
    cursor: pointer;
}

.u-bolder {
    font-weight: 700;
}

img:hover {
    filter: grayscale(0);
    transform: scale(1.01);
}

.no-grayscale {
    filter: grayscale(0);
}

.container {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
}

@media (min-width: 1441px) {
    .container {
        padding-left: var(--gap);
        padding-right: var(--gap);
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
}

@media (min-width: 1441px) {
    .header {
        padding-left: var(--gap);
        padding-right: var(--gap);
    }
}

.headline {
    margin-top: 4px;
    font-family: var(--f-headline);
    font-size: var(--f-xxxxl);
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.05;
}

@media (min-width: 660px) {
    .headline {
        letter-spacing: 0.4px;
    }
}

.page-headline {
    margin-top: 32px;
    margin-bottom: 32px;
}

.headline-2 {
    font-size: var(--f-xxxxl);
    font-family: var(--f-headline);
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    line-height: 1.05;
    margin-top: 24px;
    margin-bottom: 16px;
}

.nav {
    display: none;
    margin-top: 24px;
    margin-right: 12px;
}

.nav-item {
    font-size: var(--f-m);
    cursor: pointer;
}

.nav-item.active {
    border-bottom: 14px solid var(--color);
    margin-bottom: 12px;
}

.sub-headline {
    margin: 0;
    margin-top: 32px;
    margin-bottom: 160px;
}

@media (min-width: 375px) {
    .sub-headline {
        margin-bottom: 190px;
    }
}

@media (min-width: 660px) {
    .sub-headline {
        margin-bottom: 290px;
    }
}

.img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.img-grid {
    display: grid;
    grid-gap: 12px;
}

@media (min-width: 1441px) {
    .img-grid {
        grid-gap: var(--gap);
    }
}

@media (min-width: 660px) {
    .img-grid-1 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (min-width: 660px) {
    .img-grid-2 {
        grid-template-columns: 1fr 2.247fr 1fr 1fr;
    }
}

@media (min-width: 660px) {
    .img-grid-3 {
        grid-template-columns: 1fr 2.247fr 1fr 1fr;
    }
}

@media (min-width: 660px) {
    .img-grid-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (min-width: 660px) {
    .img-grid-start {
        grid-template-columns: 2fr 1fr 1fr 2fr;
    }
}

@media (min-width: 660px) {
    .img-galerie-grid-1 {
        grid-template-columns: 2fr 1fr 1fr 1fr;
    }
}

/* stuff for tooltip move somewhere else later*/
[data-tip] .tooltip {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visiblity 0s;
    font-family: sans-serif;
    font-size: 130px;
    font-weight: 900;
    color: white;
    text-shadow: 1.2px 1.2px 0px rgba(0, 0, 0, 0.22);
}

[data-tip]:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.link {
    font-size: var(--f-l);
    opacity: 0.99;
    line-height: 1.5;
    text-decoration: none;
    color: inherit;
}

.link-primary {
    color: var(--color);
    font-style: italic;
}

.footer {
    margin-top: 24px;
    margin-bottom: 24px;
}

@media (min-width: 800px) {
    .read-more-link {
        display: block;
    }
}

video[src=""],
img[src=""] {
    outline: 1px dotted black;
    height: 220px;
    width: 100%;
}

.marginTop {
    margin-top: 18px;
}

@media (min-width: 1441px) {
    .marginTop {
        margin-bottom: var(--gap);
    }
}

.marginTopLarge {
    margin-top: 64px;
}

.marginBottom {
    margin-bottom: 18px;
}

@media (min-width: 1441px) {
    .marginBottom {
        margin-bottom: var(--gap);
    }
}

.paddingBottom {
    padding-bottom: 16px;
}

.paddingBottomLarge {
    padding-bottom: 64px;
}

dl {
    margin: 0;
}

dd {
    margin-left: 0;
    margin-bottom: 42px;
}

dt {
    font-weight: 800;
    margin-bottom: 8px;
}

.cv-section {
    display: grid;
    grid-gap: 18px;
    font-size: var(--f-m);
}

.CVtable {
    font-size: var(--f-m);
}

@media (min-width: 700px) {
    .cv-section {
        grid-template-columns: 1.33fr 2.33fr;
    }
}

.cv-section-info-wrapper {
    display: grid;
}

@media (min-width: 700px) {
    .cv-section-info-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

.audio-container + .audio-container {
    margin-top: 68px;
}

.audio-player {
    display: grid;
    grid-template-columns: 1fr 6fr;
    justify-items: stretch;
}

@media (min-width: 600px) {
    .audio-player {
        grid-template-columns: 1fr 10fr;
        grid-auto-flow: dense;
    }
}

.audio-player-button-wrapper {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.audio-player-button {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    outline: 0;
    border: 0;
    align-items: center;
    justify-content: center;
    border: 3px solid #000;
    background: none;
    font-weight: 800;
    font-size: var(--f-s);
    cursor: pointer;
    color: inherit;
}

@media (min-width: 1441px) {
    .audio-player-button {
        width: 2.5vw;
        height: 2.5vw;
        border-width: 0.2vw;
    }
}

.audio-videos {
    display: grid;
}

@media (min-width: 769px) {
    .audio-videos {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
}

.galerie-hint {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 80px;
    font-size: var(--f-xxxxl);
    z-index: 1;
    color: white;
    font-weight: 600;
    font-style: italic;
    text-decoration: underline;
}

.p-description {
    font-size: var(--f-m);
    margin: 0;
    line-height: 1.5;
    position: relative;
}

.p-description-container {
    margin-top: 12px;
}

.video-section + .video-section {
    margin-top: 64px;
}

@media (min-width: 769px) {
    .video-sections {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 120px;
    }

    .video-section + .video-section {
        margin-top: auto;
    }
}

.spinner {
    position: absolute;
    bottom: 0;
    top: 25px;
    left: 86px;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid var(--color);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

table {
    border-collapse: collapse;
    width: 100%;
}

tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 42px;
    line-height: 1.44;
}

tr > td {
    flex-grow: 1;
    width: 100%;
}

tr > td + td {
    margin-top: 4px;
}

@media (min-width: 660px) {
    tr {
        border-bottom: 1px solid #f2f2f2;
        height: 60px;
    }

    tr > td {
        width: auto;
    }
}

@media (min-width: 660px) {
    tr {
        display: table-row;
        border-bottom: 1px solid #f2f2f2;
        height: 60px;
    }
}

td:first-of-type {
    width: 150px;
}

.img-video-container {
    position: relative;
}

/* medium zoom plugin -> remove grayscale */
.medium-zoom--opened > img {
    filter: grayscale(0);
}

.play-button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
    padding: 0;
    margin: 0 auto;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.26);
    transition: background-color 0.5s ease;
    pointer-events: none;
}

.play-button::after {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 14px);
    display: block;
    content: "";
    box-sizing: border-box;
    border-color: transparent transparent transparent #ffffff75;
    border-style: solid;
    border-width: 26px 0 26px 40px;
}

.sentence-email {
    font-size: var(--f-l);
}
